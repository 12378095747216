import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import ImageViewer from './ImageViewer';
import products from './data/products.json';
import { Container, Button } from 'react-bootstrap';

const ProductDetails = () => {
  const params = useParams();
  const [product, setProduct] = useState({});
  useEffect(() => {
    if (params.productId) {
      const selectedProductArr = products.products.filter(
        (prd) => prd.id === params.productId
      );
      if (selectedProductArr.length > 0) {
        const selectedProduct = selectedProductArr[0];
        setProduct({});
        setProduct(selectedProduct);
      }
    }
  }, [params]);

  const mainContainer = {
    backgroundColor: '#fae1d1',
    minHeight: '100vh',
  };

  const backButton = {
    margin: '1rem 0',
    backgroundColor: '#444',
    borderColor: '#444',
  };

  const headingStyle = {
    backgroundColor: '#444',
    color: '#fff',
  };

  const descpStyle = {
    padding: '2em',
    backgroundColor: '#444',
    color: '#fff',
  };

  return (
    <Container
      fluid
      style={mainContainer}
    >
      <Link to='/products'>
        <Button
          variant='secondary'
          style={backButton}
        >
          &#8592; Back to Products
        </Button>
      </Link>
      <h1 style={headingStyle}>{product.name}</h1>
      <br></br>
      <ImageViewer
        images={product.images ? product.images : ['empty-image.jpg']}
      />
      <br></br>
      <Container
        fluid
        style={descpStyle}
      >
        <h3>{product.short_description}</h3>
        <p>{product.long_description}</p>
      </Container>
      <br></br>
    </Container>
  );
};

export default ProductDetails;
