import { useState } from 'react';
import { Container, Row, Col, Image, Modal } from 'react-bootstrap';

const ImageViewer = ({ images }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (index) => {
    setSelectedImage(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <Container>
      <Row>
        {images.map((image, index) => (
          <Col
            key={index}
            xs={4}
            onClick={() => openModal(index)}
          >
            <Image
              src={image}
              thumbnail
            />
          </Col>
        ))}
      </Row>
      <Modal
        show={showModal}
        onHide={closeModal}
        size='lg'
      >
        <Modal.Body>
          {selectedImage !== null && (
            <Image
              src={images[selectedImage]}
              alt={`Image ${selectedImage + 1}`}
              fluid
            />
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ImageViewer;
