import React from 'react';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import products from './data/products.json';
import { Link } from 'react-router-dom';

const Products = () => {
  const mainContainer = {
    backgroundColor: '#fae1d1',
  };

  const productCard = {
    backgroundColor: '#444',
    color: '#fff',
    margin: '3em',
  };

  const heading = {
    backgroundColor: '#444',
    color: '#fff',
  };

  const button = {
    backgroundColor: '#ED8C4C',
    borderColor: '#444',
  };

  const productsList = products.products.map((prd) => {
    return (
      <Col
        key={prd.id}
        xs={12}
        md={6}
      >
        <Card style={productCard}>
          <Card.Img
            variant='top'
            src={prd.images[0]}
          />
          <Card.Body>
            <Card.Title>{prd.name}</Card.Title>
            <Card.Text>{prd.short_description}</Card.Text>
            <Button
              as={Link}
              variant='primary'
              style={button}
              to={prd.id}
            >
              Learn More
            </Button>
          </Card.Body>
        </Card>
      </Col>
    );
  });

  return (
    <Container
      fluid
      style={mainContainer}
    >
      <br></br>
      <h1 style={heading}>Check out our products!</h1>
      <Row className='justify-content-center'>{productsList}</Row>
    </Container>
  );
};

export default Products;
