import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faPhoneAlt,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const mainContainer = {
    minHeight: '100vh',
    backgroundColor: '#fae1d1',
  };

  const subContainer = {
    backgroundColor: '#444',
    color: '#fff',
    padding: '4em',
  };

  const submitButton = {
    backgroundColor: '#ED8C4C',
    borderColor: '#ED8C4C',
  };

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    emailjs.sendForm(
      'service_1ok0g4k',
      'template_hhwkjlw',
      event.target,
      '9wicbLpl-fhYPewo9'
    );
  };

  return (
    <Container
      fluid
      style={mainContainer}
    >
      <br></br>
      <Container
        fluid
        style={subContainer}
      >
        <Row>
          <Col>
            <h2>Contact Us</h2>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <Form.Group controlId='formName'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type='text'
                  placeholder='Enter your name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  name='user_name'
                />
                <Form.Control.Feedback type='invalid'>
                  Please enter your name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId='formEmail'>
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  required
                  type='email'
                  placeholder='Enter your email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name='user_email'
                />
                <Form.Control.Feedback type='invalid'>
                  Please enter a valid email address.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId='formMessage'>
                <Form.Label>Message</Form.Label>
                <Form.Control
                  required
                  as='textarea'
                  rows={3}
                  placeholder='Enter your message'
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  name='message'
                />
                <Form.Control.Feedback type='invalid'>
                  Please enter a message.
                </Form.Control.Feedback>
              </Form.Group>
              <br></br>
              <Button
                variant='primary'
                type='submit'
                style={submitButton}
              >
                Submit
              </Button>
            </Form>
          </Col>

          <Col>
            <h2>Our Address</h2>
            <p>
              <FontAwesomeIcon icon={faMapMarkerAlt} /> Shop No.6, Building No
              7, Ground Floor, Sai Darshan, Tembhode, Palghar (West),
              Maharashtra - 401 404, India
            </p>
            <p>
              <FontAwesomeIcon icon={faPhoneAlt} /> +91 9820550730
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} /> sugamkhopkar@gmail.com
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Contact;
