import { Carousel, Row, Col, Container } from 'react-bootstrap';

const Home = () => {
  const carousalCaption = {
    backgroundColor: '#444',
  };

  const homeBackground = {
    backgroundColor: '#fae1d1',
  };

  const subContainer = {
    backgroundColor: '#444',
    color: '#fff',
    padding: '4em',
  };

  const headingStyle = {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '3rem',
    marginBottom: '2rem',
  };

  const subheadingStyle = {
    textAlign: 'center',
    fontSize: '1.5rem',
    marginBottom: '2rem',
  };

  const paragraphStyle = {
    fontSize: '1.2rem',
    lineHeight: '1.8',
    textAlign: 'justify',
  };

  return (
    <>
      <Row>
        <Col>
          <Carousel>
            <Carousel.Item>
              <img
                className='d-block w-100'
                src='https://res.cloudinary.com/dbmgajpsn/image/upload/v1682461693/sugam-pharma/Allprod_yjquoj.jpg'
                alt='First slide'
                height='550em'
              />
              <Carousel.Caption style={carousalCaption}>
                <h3>Unlocking the Power of Medicine to Improve Lives!</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className='d-block w-100'
                src='https://res.cloudinary.com/dbmgajpsn/image/upload/v1682461693/sugam-pharma/Allprodopen_xuhe26.jpg'
                alt='Second slide'
                height='550em'
              />
              <Carousel.Caption style={carousalCaption}>
                <h3>Check out our new products!</h3>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <Row style={homeBackground}>
        <Col>
          <Container style={subContainer}>
            <h2 style={headingStyle}>Experties</h2>
            <p style={paragraphStyle}>
              Sugam Pharma is a pioneering pharmaceutical company that
              specializes in developing cutting-edge medicines for patients
              worldwide. Our range of top-quality products includes Calcium
              Supplements, Iron Tablets, Antibiotics, Antiallergics, Nervous
              System Improvements, Coenzymes, and Antiacids. With a mission to
              enhance the quality of life for patients, our innovative medicines
              are designed to deliver measurable improvements in health
              outcomes. At Sugam Pharma, we are committed to advancing the
              frontiers of healthcare and setting new standards in the
              pharmaceutical industry. Join us on our journey to improve patient
              lives and experience the difference today.
            </p>
            <p style={paragraphStyle}>
              At Sugam Pharma, we offer a diverse range of cutting-edge
              pharmaceutical solutions to support healthy living. Our innovative
              product line includes treatments for optimal bone health, iron
              deficiencies, infections, allergies, nerve weakness, poor
              vitality, and heartburn. Each product is the culmination of
              rigorous research and development, and has been carefully crafted
              to meet the specific needs of our valued patients.
            </p>
          </Container>
        </Col>
      </Row>
      <Row style={homeBackground}>
        <Col>
          <Container style={subContainer}>
            <h2 style={headingStyle}>Core Competencies</h2>
            <h3 style={subheadingStyle}>Innovation at Its Finest</h3>
            <p style={paragraphStyle}>
              Our world-class team of scientists and researchers is tirelessly
              dedicated to revolutionizing drug development and discovering
              life-changing treatments for patients worldwide. With advanced
              technologies and state-of-the-art facilities, we consistently
              achieve groundbreaking results in the field of pharmaceutical
              research and development.
            </p>
            <h3 style={subheadingStyle}>Manufacturing and Quality Control</h3>
            <p style={paragraphStyle}>
              At Sugam Pharma, we take pride in our world-class manufacturing
              facilities and rigorous quality control processes. With advanced
              technologies and strict adherence to industry standards, we ensure
              that our products meet the highest safety and efficacy standards.
              Our commitment to excellence extends from sourcing high-quality
              raw materials to packaging and labelling, ensuring that our
              products are of the highest quality possible.
            </p>
            <h3 style={subheadingStyle}>Customer Service</h3>
            <p style={paragraphStyle}>
              At Sugam Pharma, we are committed to providing the highest level
              of customer service. Our dedicated team is available 24/7 to
              answer any questions or concerns our customers may have. We
              measure our success by our response times and customer
              satisfaction ratings, which consistently exceed industry
              standards. Our proactive communication ensures that our customers
              are always up-to-date on the latest product information and
              delivery status.
            </p>
          </Container>
        </Col>
      </Row>
    </>
  );
};

export default Home;
