import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const About = () => {
  const mainContainer = {
    minHeight: '100vh',
    backgroundColor: '#fae1d1',
    padding: '2rem',
  };

  const subContainer = {
    backgroundColor: '#444',
    color: '#fff',
    padding: '4em',
  };

  const headingStyle = {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '3rem',
    marginBottom: '2rem',
  };

  const subheadingStyle = {
    textAlign: 'center',
    fontSize: '1.5rem',
    marginBottom: '2rem',
  };

  const paragraphStyle = {
    fontSize: '1.2rem',
    lineHeight: '1.8',
    textAlign: 'justify',
  };

  return (
    <Container
      fluid
      style={mainContainer}
    >
      <Container
        fluid
        style={subContainer}
      >
        <Row>
          <Col>
            <h1 style={headingStyle}>Who we are!</h1>
            <h2 style={subheadingStyle}>
              "Unlocking the Power of Medicine to Improve Lives."
            </h2>
            <p style={paragraphStyle}>
              We are a pharmaceutical startup dedicated to developing and
              delivering innovative, effective, and affordable healthcare
              products. Our goal is to improve access to quality healthcare and
              provide a better quality of life to those in need. Our team of
              experienced and dedicated professionals is committed to providing
              the highest quality of care.
            </p>
            <p style={paragraphStyle}>
              We are focused on the development of products and treatments to
              address a variety of health conditions. We are also committed to
              collaborating with other healthcare providers to ensure that our
              products are safe and effective. We strive to be a leader in the
              industry and to provide our customers and partners with the best
              possible service. We are dedicated to improving the quality of
              life for all and to ensuring the success of our customers.
            </p>
            <p style={paragraphStyle}>
              We are proud to be a part of the healthcare revolution and to be a
              part of the future of healthcare.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default About;
