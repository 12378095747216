import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <Container
      fluid
      className='bg-dark text-light'
    >
      <Row className='py-3'>
        <Col className='text-center'>
          <p>&copy; 2023 Sugam Pharma. All Rights Reserved.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
