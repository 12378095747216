import React from 'react';
import { useState } from 'react';
import { Container, Nav, Navbar, Image } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faBriefcaseMedical,
  faHospitalUser,
  faAddressBook,
} from '@fortawesome/free-solid-svg-icons';

const Navigation = () => {
  const [logoStyle, setLogoStyle] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const onNavbarToggle = (e) => {
    if (e === true) {
      setLogoStyle(logoPositon);
    } else {
      setLogoStyle({});
    }
  };

  const navBar = {
    backgroundColor: '#ED8C4C',
  };

  const navLink = {
    color: '#444',
    fontSize: '1.25rem',
    padding: '1.25rem',
  };

  const navLinkHighlighted = {
    color: '#fff',
    fontSize: '1.25rem',
    padding: '1.25rem',
  };

  const logoPositon = {
    paddingLeft: '8.75rem',
  };
  return (
    <div>
      <Navbar
        collapseOnSelect
        expand='lg'
        variant='dark'
        style={navBar}
        onToggle={onNavbarToggle}
      >
        <Navbar.Brand style={logoStyle}>
          <Container fluid>
            <Image
              alt='Sugam Pharma'
              src='logo.png'
              width='100rem'
              className='d-inline-block align-top'
              fluid
            />
          </Container>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav
            fill
            className='mr-auto'
          >
            <Nav.Link
              onClick={(e) => {
                e.preventDefault();
                navigate('');
              }}
              style={location.pathname === '/' ? navLinkHighlighted : navLink}
            >
              <FontAwesomeIcon icon={faHome} /> HOME
            </Nav.Link>
            <Nav.Link
              onClick={(e) => {
                e.preventDefault();
                navigate('products');
              }}
              style={
                location.pathname === '/products' ? navLinkHighlighted : navLink
              }
            >
              <FontAwesomeIcon icon={faBriefcaseMedical} /> PRODUCTS
            </Nav.Link>
            <Nav.Link
              onClick={(e) => {
                e.preventDefault();
                navigate('about');
              }}
              style={
                location.pathname === '/about' ? navLinkHighlighted : navLink
              }
            >
              <FontAwesomeIcon icon={faHospitalUser} /> ABOUT US
            </Nav.Link>
            <Nav.Link
              onClick={(e) => {
                e.preventDefault();
                navigate('contact');
              }}
              style={
                location.pathname === '/contact' ? navLinkHighlighted : navLink
              }
            >
              <FontAwesomeIcon icon={faAddressBook} /> CONTACT US
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Navigation;
